import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { createInstance } from 'i18next';
import Redux, { updatePath } from "@/action"
import { HeaderNavbar } from '@/components/mobile';
import { Link } from "@/helpers/common_helper";
import Installer from '@/components/installer';
import CommingSoon from '@/components/commingsoon';
import React, { useEffect, useRef, useState } from 'react';
import { _getURL, _postURL } from "@/api/index";
import { isEmpty, map } from 'lodash';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { Button } from '@mantine/core';
import moment from 'moment';
import LanguageModal from '@/components/languagemodal';
import { useRouter } from 'next/router';

export default function Index(props) {
  const { store, dispatch } = Redux();
  const { t, device } = props;
  const [banner, setBanner] = useState([])
  const [euro2024Match, setEuroMatch] = useState([])
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const [isFirstClientRender, setIsFirstClientRender] = useState(true);
  const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
  const router = useRouter();
  const [categories, setCategories] = useState([]);
  const [news, setNews] = useState([]);
  const [activeTab, setActiveTab] = useState('news');
  const [hasMoreNews, setHasMoreNews] = useState(true);
  const [currentPageNews, setCurrentPageNews] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { locale, pathname, asPath, query } = router;
  const [language, setLanguage] = useState(locale);

  const filteredNews = selectedCategory ? news.filter(item => item.key_code === selectedCategory) : news;

  const now = new Date();

  function formatTabName(name) {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const handleSignUpClick = () => {
    router.push('/sign-up');
  };

  const handleNavigate = (path) => {
    router.push(path);
  };

  const fetchNews = async (page = 1, lang = language) => {
    try {
      const response = await fetch(`/api/external/news?p=${page}&lang=${lang}`);
      const data = await response.json();
      if (data.data.length > 0) {
        setNews(prevNews => page === 1 ? data.data : [...prevNews, ...data.data]);
        setHasMoreNews(data.paginate.curr_page < data.paginate.total_pages);
      } else {
        setHasMoreNews(false);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const fetchCategories = async (type, lang = language) => {
    try {
      const response = await fetch(`/api/external/categories?type=${type}&lang=${lang}`);
      const data = await response.json();
      if (data.length > 0) {
        setCategories([{ category_name: t('All'), key_code: null }, ...data]);
      } else {
        console.error('No categories found');
        setCategories([]);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setCategories([]);
    }
  };

  const renderTab = (tabName) => {
    // Define paths based on the tab name
    const pathMap = {
      'news': '/news',
      'video': '/video',
      'event': '/event'
    };

    const isActive = activeTab === tabName;
    const tabClass = isActive ? "tab active" : "tab";
    return (
      <div className={tabClass} onClick={() => handleNavigate(pathMap[tabName])}>
        {t(formatTabName(tabName))}
      </div>
    );
  };

  const handleCategorySelect = (key_code) => {
    setSelectedCategory(key_code);
    setNews([]);
    setCurrentPage(1);

    if (key_code === null) {
      fetchNews(1, '');
    } else {
      router.push(`/news/${key_code}`);
    }
  };

  const renderNewsItems = () => {
    if (activeTab != 'news') return null;

    return (
      <div className="grid-container">
      {filteredNews.length > 0 ? filteredNews.map((item, index) => {
          const categoryKey = item.key_code;
          const newsSlug = item.news_id;  
          const urlTitle = item.url_title;
          return (
              <div key={index} className="grid-item" onClick={() => {
                router.push(`/news/${encodeURIComponent(categoryKey)}/${encodeURIComponent(newsSlug)}/${encodeURIComponent(urlTitle)}`);
              }}>
                  {item.img_path && <img src={item.img_path} alt={item.news_title} />}
                  <h2>{item.news_title}</h2>
                  <p className="home-datetime">{new Date(item.news_datetime).toLocaleDateString()}</p>
              </div>
          );
      }) : <div className='no-item'><p>No news available in this category.</p></div>}
  </div>
    );
  };

  const renderCategories = () => {
    return (
      <div className="nav-tabs">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`nav-tabs__item ${selectedCategory === category.key_code ? 'active' : ''}`}
            onClick={() => handleCategorySelect(category.key_code)}
            style={{ cursor: 'pointer' }}
          >
            {category.category_name}
          </div>
        ))}
      </div>
    );
  };

  const loadMore = async () => {
    if (activeTab === 'news' && hasMoreNews) {
      const nextPage = currentPageNews + 1;
      await fetchNews(nextPage);
      setCurrentPageNews(nextPage);
    }
  };

  useEffect(() => {
    if (isFirstClientRender) {
      const modalShown = sessionStorage.getItem('languageModalShown');
      if (!modalShown) {
        setLanguageModalOpen(true);
        sessionStorage.setItem('languageModalShown', 'true');
      }
    }

    _getBanner();
    _getEuro2024();
    fetchCategories('news');
    fetchNews();
  }, [isFirstClientRender]);

  useEffect(() => {
    if (activeTab === 'news') {
      fetchCategories(activeTab);
    } else {
      setCategories([]);
    }
  }, [activeTab]);

  const _getBanner = async () => {
    try {
      const response = await _getURL("/api/common/getBanner?type=" + (device === 'mobile' ? 1 : 2));
      if (response.data && Array.isArray(response.data)) {
        setBanner(response.data);
      } else {
        console.error('Banner data is not an array:', response.data);
        setBanner([]); 
      }
    } catch (error) {
      console.error('Error fetching banner data:', error);
      setBanner([]); 
    }
  }

  const _onClickBanner = async (id) => {
    try {
      await _postURL("/api/common/updateBannerClick", { id });
    } catch (error) {
    }
  }

  const _getEuro2024 = async () => {
    try {
      const { data } = await _getURL("/api/thesport/getEuro2024");
      setEuroMatch(data)
    } catch (error) {
    }
  }

  const handleLanguageChange = (language) => {
    localStorage.setItem('preferredLanguage', language);
    setLanguageModalOpen(false);
  };

  const toggleLanguage = () => {
    const newLang = language === 'en' ? 'zh' : 'en';
    router.push({ pathname, query }, undefined, { locale: newLang });
    setLanguage(newLang);
    fetchNews(1, newLang);
    fetchCategories('news', newLang)
  };

  // if (device == 'mobile' && isFirstClientRender) {
  //   return <LandingPage setFirstTimeLandingPage={setIsFirstClientRender}/>
  // }

  // mobile platform
  if (device == 'mobile') {
    return (
      <>
        <HeaderNavbar page="home" {...props} />
        <div className="tabs-container">
          {renderTab('news')}
          {renderTab('video')}
          {renderTab('event')}
        </div>
        <div className="category-container" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {renderCategories()}
        </div>
        {activeTab === 'news' && <div className="news-container">
          {renderNewsItems()}
          {activeTab === 'news' && hasMoreNews && filteredNews.length > 0 && (
            <div style={{ textAlign: 'center', margin: '20px' }}>
              <button onClick={loadMore} className="load-more-btn">Load More</button>
            </div>
          )}
        </div>}
      </>
    );
  }

  const yesterday = moment().subtract(1, 'day').startOf('day');
  const tomorrow = moment().add(1, 'day').startOf('day');
  const today = moment().startOf('day');

  return (
    <>
      {/* <Head>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <link rel="canonical" href={meta.canonical} />
        <meta property="og:title" content={meta.og_title} />
        <meta property="og:description" content={meta.og_description} />
        <meta property="og:image" content={meta.og_image} />
        <meta property="og:site_name" content={meta.og_site_name} />
        <meta property="og:url" content={meta.og_url} />
      </Head> */}
      <div className="banner-container">
        {banner.map((item, index) => (
          <div key={index} onClick={() => _onClickBanner(item.id)}>
            <img src={item.img_path} alt={item.title} />
            <div>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="tabs-container">
        <div className={`tab ${activeTab === 'news' ? 'active' : ''}`} onClick={() => handleNavigate('/news')}>{t('News')}</div>
        <div className={`tab ${activeTab === 'video' ? 'active' : ''}`} onClick={() => handleNavigate('/video')}>{t('Videos')}</div>
        <div className={`tab ${activeTab === 'event' ? 'active' : ''}`} onClick={() => handleNavigate('/event')}>{t('Events')}</div>

        <div className="full-width-line"></div>
      </div>
      {renderCategories()}
      {renderNewsItems()}
      {activeTab === 'news' && hasMoreNews && filteredNews.length > 0 && (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <button onClick={loadMore} className="load-more-btn">Load More</button>
        </div>
      )}
      <LanguageModal
        changeLanguage={props.handleLanguageChange}
        opened={props.isLanguageModalOpen}
        setOpened={props.setLanguageModalOpen}
      />
    </>
  );
}
// export async function getServerSideProps({ locale, }) {
//   return {
//     redirect: {
//       permanent: false,
//       destination: "/app-download",
//       ...(await serverSideTranslations(locale, ['common'])),
//     }
//   }
// }

export async function getStaticProps({ locale }) {
  const translation = await serverSideTranslations(locale, ['common']);

  const i18n = createInstance({
    lng: translation?._nextI18Next?.initialLocale,
    ns: translation?._nextI18Next?.ns,
    resources: translation?._nextI18Next?.initialI18nStore,
  });
  await i18n.init();

  return {
    props: {
      placementPage: "home",
      meta: {
        title: i18n.t('home_meta_title', { ns: 'common' }),
        description: i18n.t('home_meta_description', { ns: 'common' }),
        canonical: i18n.t('home_canonical_tag', { ns: 'common' }),
        og_title: i18n.t('home_meta_title', { ns: 'common' }),
        og_description: i18n.t('home_meta_description', { ns: 'common' }),
        og_image: i18n.t('home_og_image', { ns: 'common' }),
        og_site_name: i18n.t('home_og_site_name', { ns: 'common' }),
        og_url: i18n.t('home_og_url', { ns: 'common' }),
        msvalidate_01:"F0286C8EA54A4F10029FB675C5761CB0"
      },
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
