const CommingSoon = ({ t }) => {

  return (
    <>
      <div className="container bg-shadow" style={{
        textAlign: "center",
        padding: "100px",
        backgroundColor: "#fff",
        margin: "20px auto 60px auto",
      }}>
        <img src="/assets/images/mascot.png" style={{ width: "150px" }} />
        <div style={{ display: "block", textAlign: "center", width: "100%", margin: "0 auto" }}>
          <p style={{ color: "black" }}>{t("Coming soon, stay tuned")}</p>
        </div>
      </div>
    </>
  );
};

export default CommingSoon;
